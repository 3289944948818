import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

// react-pro-sidebar Components
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";

//import dashboard Pages
import { DashboardIcon } from "../../assets/icons";
import { dashboardPages } from "../../data/dashboardRoutes";
import { ScreenShare } from "@mui/icons-material";

// Sidebar styles
const sidebarStyles = {
	"&.ps-sidebar-root": {
		border: "none",
		width: "290px",
		minWidth: "290px",
	},

	"& .ps-sidebar-container": {
		width: "290px",
		boxShadow: "none",
		height: "100vh",
	},
};

const menuItemStyles = {
	root: {
		fontSize: "18px",
		fontWeight: 500,
		color: "#f6f6f6",
		"& .ps-menuitem-root": {
			color: " #200407",
			"& .ps-menu-button": {
				paddingRight: "20px",
			},
		},
	},

	/* arrow sub menu icons*/
	SubMenuExpandIcon: {
		span: {
			width: "9px",
			height: "9px",
		},
		color: "#f6f6f6",
	},

	/* menu items */
	button: {
		height: "58px",
		[`& .dashboard-icon path `]: {
			color: "white",
			fill: "white",
		},
		[`&.active`]: {
			backgroundColor: "#b19f70",
			color: " #fff",

			[`& svg path `]: {
				color: " #fff",
				fill: " #fff",
			},

			[`& span.ps-submenu-expand-icon `]: {
				color: " #fff",
			},
		},
		"&:hover": {
			backgroundColor: "#b19f70",
			color: "#fff",
			[`& svg path `]: {
				color: " #fff",
				fill: " #fff",
			},
			[`& span.ps-submenu-expand-icon `]: {
				color: " #fff",
			},
		},
	},

	/* sub menu content*/
	subMenuContent: ({ level }) => ({
		backgroundColor: level === 0 && "#FFF783",
		color: "#fff",

		[`&.active`]: {
			backgroundColor: "#b19f70",
			color: " #fff",
		},
		"&:hover": {
			backgroundColor: "#b19f70",
			color: " #fff",
		},
	}),
};

const SideBar = () => {
	// to drawing Sidebar in UI
	const pagesRoutes = dashboardPages.map((page, index) => (
		<Fragment key={page?.id}>
			{page?.subMenu ? (
				<SubMenu
					label={page?.pageTitle}
					icon={page?.icon}
					key={index}
					component={<NavLink to={`${page?.navigateTo}`} />}>
					<MenuItem
						icon={<span className='w-2 h-2 bg-black rounded-full'></span>}
						component={<NavLink to={`${page?.subMenu?.navigateTo}`} />}>
						{page?.subMenu?.pageTitle}
					</MenuItem>
				</SubMenu>
			) : (
				<MenuItem
					key={index}
					component={<NavLink to={`${page?.navigateTo}`} />}
					icon={page?.icon}>
					{page?.pageTitle}
				</MenuItem>
			)}
		</Fragment>
	));
	return (
		<>
			<Sidebar
				rtl={true}
				rootStyles={sidebarStyles}
				className='!sticky top-[94px] w-fit '
				backgroundColor='#387858'>
				<Menu
					menuItemStyles={menuItemStyles}
					className=' nav-bar-menu  overflow-y-auto pb-20'>
					<MenuItem
						onClick={() => window.open("https://alausra.sa/")}
						icon={<ScreenShare className='dashboard-icon' />}>
						عرض الموقع
					</MenuItem>
					<MenuItem
						component={<NavLink to='/' />}
						icon={<DashboardIcon className='dashboard-icon' />}>
						لوحة التحكم
					</MenuItem>
					{pagesRoutes}
				</Menu>
			</Sidebar>
		</>
	);
};

export default SideBar;
