import axios from "axios";
import { toast } from "react-toastify";
axios.defaults.baseURL = "https://api.alausra.sa/";

axios.interceptors.request.use(
	(req) => {
		// Do something before request is sent
		if (!req.url.includes("login")) {
			let token = localStorage.getItem("token") || null;
			if (token !== null) {
				req.headers.Authorization = `Bearer ${token}`;
				// req.headers.Locale = localStorage.getItem("language") || "en"
			}
		}
		// console.log(req)
		return req;
	},
	(error) => {
		console.log(error);
		// Do something with request error
		return Promise.reject(error);
	}
);

axios.interceptors.response.use((success) => {
	// console.log(success?.status)
	// console.log(success?.config?.method)
	if (
		(success?.status == 200 &&
			["delete", "patch", "post", "pull", "put"].includes(
				success?.config?.method
			)) ||
		(success?.status == 201 &&
			["delete", "patch", "post", "pull", "put"].includes(
				success?.config?.method
			))
	) {
		// console.log("delete")

		toast.success(success?.data?.message, {
			theme: "light",
		});
	}
	return success;
});
