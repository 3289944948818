import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changePageDataAction } from "../../../RTK/Reducers/HomePageReducer";
import { useFieldArray, useForm } from "react-hook-form";

import {
	BlogIcon,
	DeleteIcon,
	ImagesIcon,
	LinkIcon,
	PlusIcon,
	PreviewIcon,
	SaveIcon,
	TextIcon,
	UploadImagesIcon,
} from "../../../assets/icons";
import {
	PagesBreadcrumbs,
	PreviewVideoModal,
	SectionLabel,
	TextEditor,
} from "../../../components";
import Context from "../../../context/Context";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// --link--image--
// text-main_title-sub_title-title-video--customer_name-customer_info
let returnSchemaFunctions = (type) => {
	if (type == "type") {
		return {
			data: Yup.object().shape({
				title: Yup.string().required("قم بإضافة اسم القسم"),
				ingredients: Yup.array()
					// test select options and radio buttons
					.of(
						Yup.object().shape({
							link: Yup.string().optional("قم بإضافة ربط الملف"),
							image: Yup.mixed().required("قم بإضافة الملف"),
							status: Yup.mixed().optional("قم بإضافة الحالة"),
						})
					),
			}),
			defaults: [
				{
					link: "",
					image: null,
					status: false,
					previewImgOrVideo: null,
				},
			],
		};
	}
};
const HomeSectionNew = ({ featuresBlocks, reload, setReload }) => {
	let { name } = useParams();
	let dispatch = useDispatch();
	let { section_type } = useSelector((state) => state.HomePageReducer);
	const [schema, setSchema] = useState({
		data: Yup.object().shape(),
		defaults: [
			{
				link: "",
				image: null,
				status: false,
				previewImgOrVideo: null,
			},
		],
	});
	const AddNewSliderModalContext = useContext(Context);
	const {
		isEdit,
		setVideoSrc,
		setOpenPreviewModal,
		setQuestionTitle,
		currentQuestions,
		setAnswerQuestion,
	} = AddNewSliderModalContext;
	const AddMoreInfoModalContext = useContext(Context);
	const {
		deleteReload,
		setBlockIndex,

		setDeleteReload,
		setMoreInfoDescription,
		setOpenAddMoreInfoModal,
		setMoreInfoContentIndex,
	} = AddMoreInfoModalContext;
	const [createBlock, setCreateBlock] = useState([
		{
			title: "",

			image: null,
			previewImgOrVideo: null,
			link: "",
			points: [
				{ icon: null, point: "", title_01: "", title_02: "", description: "" },
			],
		},
	]);

	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema?.data),
		mode: "all",
		defaultValues: {
			title: "",
			ingredients: schema?.defaults,
		},
	});
	const setSchemaRef = useRef(true);
	useEffect(() => {
		if (section_type?.type && setSchemaRef.current) {
			setSchema(returnSchemaFunctions(section_type?.type));
			console.log(returnSchemaFunctions(section_type?.type));
			setSchemaRef.current = false;
		}
	}, [section_type]);
	console.log("schema", schema);
	// watch("ingredients");
	// console.log("ingredients",watch("ingredients"))
	const {
		fields: ingredientsFields,
		append: appendIngredientField,
		remove: removeIngredientField,
	} = useFieldArray({
		control,
		name: "ingredients",
	});
	// ----------------------video-img------------------------------------
	const handleSetLinkInput = (e, index) => {
		const updatedPackInfoInput = [...createBlock];

		updatedPackInfoInput[index].link = e.target.value;
		setCreateBlock(updatedPackInfoInput);
	};
	/*----------------------------------------------------------------*
 * HANDLE CREATE THE UPLOAD VIDEO OR IMAGE INPUT
-----------------------------------------------*/
	const fileInputRef = React.createRef();

	// Function to handle file input change
	const handleSetImageInput = (blockIndex, event) => {
		const file = event?.target?.files[0];
		const imgConvert = URL.createObjectURL(file);

		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].image = file;

		updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

		setCreateBlock(updatedBlocks);
	};
	// Function to open the file input dialog
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	// Function to delete the uploaded image or video
	const handleDeleteImages = (blockIndex) => {
		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].image = null;
		setCreateBlock(updatedBlocks);

		fileInputRef.current.value = null;
	};

	// handle openPreview video modal
	const handleOpenPreviewVideoModal = (previewImgOrVideo) => {
		setOpenPreviewModal(true);
		setVideoSrc(previewImgOrVideo);
	};
	// ----------------------video-img------------------------------------
	// ----------------------------------------------------------
	const [socialMediaErrors, setSocialMediaErrors] = useState({
		snapChat: "",
		tikTok: "",
		instagram: "",
		twitter: "",
	});

	const restErrors = () => {
		setSocialMediaErrors({
			snapChat: "",
			tikTok: "",
			instagram: "",
			twitter: "",
		});
	};
	// handle delete Social Media Accounts
	const deleteSocialLink = (id) => {
		restErrors();

		const formData = new FormData();
	};

	// ========================================================================

	// handle change Social Media Status
	const changeSocialLinkStatus = (id) => {};

	useEffect(() => {
		if (featuresBlocks) {
			setCreateBlock([...featuresBlocks]);
		}
	}, [featuresBlocks]);
	// Handle Save question title and answers
	const [saveQuestionAlert, setSaveQuestionAlert] = useState(false);

	/*----------------------------------------------------------------*
   * HANDLE CREATE THE UPLOAD VIDEO OR IMAGE INPUT
  -----------------------------------------------*/
	//to handle set current icons
	const handleIconChange = (selectedIcon, blockIndex, pointIndex) => {
		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].points[pointIndex].icon = selectedIcon;
		setCreateBlock(updatedBlocks);
	};

	//handle set item to package info input
	const handleSetItems = (e, blockIndex, pointIndex) => {
		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].points[pointIndex].point = e.target.value;
		setCreateBlock(updatedBlocks);
	};
	//--------------------------------------------------------------------

	// handle open more info fro items
	const handleOpenAddMoreInfoModal = (blockIndex, pointIndex, description) => {
		setOpenAddMoreInfoModal(true);
		setBlockIndex(blockIndex);
		setMoreInfoContentIndex(pointIndex);
		setMoreInfoDescription(description);
	};
	// -------------------------------------------------------------------------------

	// handle Add NewI tem Program Advantage
	const handleAddNewItemProgramAdvantage = (blockIndex) => {
		const updatedBlocks = [...createBlock];
		updatedBlocks[blockIndex].points.push({
			icon: null,
			point: "",
			description: "",
		});
		setCreateBlock(updatedBlocks);
	};
	// -------------------------------------------------------------------------------

	// Handle delete block
	useEffect(() => {
		if (deleteReload === true) {
			setReload(!reload);
		}
		setDeleteReload(false);
	}, [deleteReload]);

	// To handle hidden save alert
	if (saveQuestionAlert === true) {
		setTimeout(() => {
			setSaveQuestionAlert(false);
		}, 2000);
	}

	// To set current questions if isEDIT
	useEffect(() => {
		if (currentQuestions && isEdit) {
			setQuestionTitle(currentQuestions?.question);
			setAnswerQuestion(currentQuestions?.answer);
		}
	}, [currentQuestions && isEdit]);
	useEffect(() => {
		return () => {
			dispatch(changePageDataAction({ name: "remove" }));
		};
	}, []);
	const programAdvantageBlock = createBlock?.map((block, blockIndex) => (
		<>
			{/* Create program items  */}
			<section className=' py-[40px] px-[20px]'>
				{/* Create item input */}

				{block?.points?.map((point, pointIndex) => (
					<div key={pointIndex} className='pb-[60px]'>
						{/* To add img-video */}
						<>
							{/* Main Title of page */}

							{/* Upload Videos or images  */}
							{(section_type?.img == true ||
								section_type?.video == true ||
								section_type?.icon == true) && (
								<section className=' mb-[25px]'>
									{/* <SectionLabel label={"صورة/ فيديو"} icon={<ImagesIcon />} /> */}
									<SectionLabel
										label={
											section_type?.img == true
												? " صورة "
												: section_type?.video == true
												? " فيديو "
												: section_type?.icon == true
												? " أيقونة "
												: null
										}
										icon={<ImagesIcon />}
									/>

									{/* Upload image or video Component */}
									<div className='w-[100%]  flex justify-start items-center gap-4'>
										{/* upload images Component*/}
										<div className='w-[100%] min-h-[100px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow p-4'>
											<div className='w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
												<input
													type='file'
													accept='image/*,video/*,icon/*,'
													onChange={(selectedImageOrVideo) => {
														handleSetImageInput(
															blockIndex,
															selectedImageOrVideo
														);
													}}
													ref={fileInputRef}
													/* hidden this input and use icon below to upload  */
													style={{ display: "none" }}
												/>

												{/* Button to trigger file input */}
												<button
													onClick={handleButtonClick}
													className='w-full h-full flex justify-center items-center'>
													{block?.image ? (
														block?.previewImgOrVideo !== null &&
														(block?.previewImgOrVideo?.endsWith(".png") ||
															block?.image?.type?.startsWith("image/")) ? (
															<div className='p-1 w-full h-full flex justify-center items-center'>
																<img
																	src={block?.previewImgOrVideo}
																	alt={block?.previewImgOrVideo}
																	className='h-full '
																/>
															</div>
														) : (
															<div className='p-1 w-full h-full flex justify-center items-center'>
																{block?.previewImgOrVideo}
															</div>
														)
													) : (
														<UploadImagesIcon
															className='w-6 h-6'
															title='تغيير صورة '
														/>
													)}
												</button>
											</div>

											{/* Image Size */}
											<div className=''>
												<span className='text-[16px] text-[#C6C6C6] font-normal '>
													المقاس المقترح: 400 * 180
												</span>
											</div>
										</div>

										{/* Save and delete videos */}
										<div className='flex justify-start items-center gap-4'>
											{/* preview video */}
											{block?.image !== null &&
												(block?.previewImgOrVideo?.endsWith(".mp4") ||
													block?.image?.type?.startsWith("video/")) &&
												block?.previewImgOrVideo !== null && (
													<button
														onClick={() =>
															handleOpenPreviewVideoModal(
																block?.previewImgOrVideo
															)
														}
														disabled={!block?.previewImgOrVideo}
														className='block cursor-pointer disabled:cursor-not-allowed'>
														<PreviewIcon
															className='w-11 h-11 preview-video-icon'
															title='معاينة الفيديو'
														/>
													</button>
												)}

											{/* delete video */}
											<button
												disabled={!block?.previewImgOrVideo}
												className='cursor-pointer disabled:cursor-not-allowed'
												onClick={() => handleDeleteImages(blockIndex)}>
												<DeleteIcon className='w-6 h-6 ' title='حذف الفيديو' />
											</button>
										</div>
									</div>

									{/* Preview Video modal That opens when you click the preview button */}
									<PreviewVideoModal />
								</section>
							)}

							{/* Embed YouTube Video  */}
							{section_type?.video == true && (
								<section className='py-[25px]'>
									<SectionLabel
										label={"رابط فيديو"}
										icon={<LinkIcon className='link-icon' />}
									/>

									<div className='w-[100%] min-h-[100px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow px-4 flex justify-center items-center'>
										<input
											type='text'
											name='link'
											value={block?.link}
											onChange={(e) => handleSetLinkInput(e, blockIndex)}
											placeholder={"https://www.fayz.youtube.com"}
											className='w-full h-11 bg-white m-auto p-5 !border-[1px] !border-solid !border-unnamed_color_119184  rounded-lg'
										/>
									</div>
								</section>
							)}
							{section_type?.link == true && (
								<div className='w-full flex-col flex justify-start items-start  mb-[30px]'>
									<SectionLabel
										label={" الرابط الخاص "}
										icon={<BlogIcon className='programBlogIcon' />}
									/>
									<div className='w-full  h-12 border-[1px] border-[#E8E8E8] bg-[##9ED1B8]  rounded flex justify-start items-center'>
										{/* Add info value to packages */}
										<input
											type='text'
											value={point?.point}
											onChange={(e) =>
												handleSetItems(e, blockIndex, pointIndex)
											}
											placeholder={`الرابط الخاص برقم ${pointIndex + 1}`}
											className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
										/>
									</div>
								</div>
							)}
						</>
						{section_type?.title_02 == true && (
							<>
								<SectionLabel label={"عنوان فرعي"} icon={<TextIcon />} />
								<div className=' min-h-[56px] mb-[25px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow flex justify-start items-center '>
									<input
										type='text'
										// placeholder='فريق عمل في خدمتكم'
										// value={point?.title_01}
										// onChange={(e) => handleSetTitleInput(e, blockIndex)}
										className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
									/>
								</div>
							</>
						)}

						{section_type?.text == true && (
							<div className='pb-[25px]'>
								<TextEditor
									placeholder={" المحتوى النصي للقسم المحتوى"}
									height={"300px"}
								/>
							</div>
						)}
						{section_type?.customer_name == true && (
							<>
								<SectionLabel label={"اسم العميل"} icon={<TextIcon />} />
								<div className=' min-h-[56px] mb-[25px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow flex justify-start items-center '>
									<input
										type='text'
										// placeholder='فريق عمل في خدمتكم'
										// value={point?.title_01}
										// onChange={(e) => handleSetTitleInput(e, blockIndex)}
										className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
									/>
								</div>
							</>
						)}
						{section_type?.customer_info == true && (
							<>
								<SectionLabel label={"معلومات عن العميل"} icon={<TextIcon />} />
								<div className=' min-h-[56px] mb-[25px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow flex justify-start items-center '>
									<input
										type='text'
										// placeholder='فريق عمل في خدمتكم'
										// value={point?.title_01}
										// onChange={(e) => handleSetTitleInput(e, blockIndex)}
										className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
									/>
								</div>
							</>
						)}
					</div>
				))}

				{/* Add more item button */}
				<div>
					<button
						onClick={() => handleAddNewItemProgramAdvantage(blockIndex)}
						title='اضافه سلايد جديد'
						className='w-[100%]  h-12 border-[2px]  border-dashed border-unnamed_color_119184   bg-[#F1F8F5]   rounded flex justify-center items-center cursor-pointer'>
						<PlusIcon className='add-icon' />
					</button>
				</div>
			</section>
		</>
	));
	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادات الصفحة الرئيسية</title>
			</Helmet>
			<div className='py-4 px-14'>
				{/*  Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						parentPage={"الرئيسية"}
						currentPage={`${name}`}
						isNested={true}
						route={"/website-home-page"}
					/>
				</section>
				<section className='p-[25px]'>
					{section_type?.main_title == true && (
						<>
							<SectionLabel label={"عنوان"} icon={<TextIcon />} />
							<div className=' min-h-[56px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow flex justify-start items-center '>
								<input
									type='text'
									className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
								/>
							</div>
						</>
					)}

					{programAdvantageBlock}
					<button
						// disabled={!answerQuestion && questionTitle}
						className='w-[154px] mt-[40px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '
						// onClick={handleAddNewSliderToCurrentArray}
					>
						<span>
							<SaveIcon />
						</span>
						<span className='text-white text-lg font-medium '>حفظ</span>
					</button>
				</section>
			</div>
		</>
	);
};

export default HomeSectionNew;
