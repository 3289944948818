import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// import components
import { PagesBreadcrumbs } from "../../../components";
import { Loader } from "../../../components/loader";

import {
	EmailIcon,
	SaveIcon,
	UploadImagesIcon,
	UserIcon,
} from "../../../assets/icons";

import "../../../index.css";

import { useDispatch, useSelector } from "react-redux";
import { EditMyAccountThunk } from "../../../RTK/Thunk/EditMyAccountThunk";
import { GetMyAccountDataThunk } from "../../../RTK/Thunk/GetMyAccountDataThunk";
import { DummyImage } from "../../../assets/images";

const EditMyAccount = () => {
	const dispatch = useDispatch();
	let { myAccountData, reload } = useSelector(
		(state) => state.MyAccountReducer
	);

	/** get contact data */
	useEffect(() => {
		if (reload) {
			dispatch(GetMyAccountDataThunk());
		}
	}, [reload]);

	const [imagePreview, setImagePreview] = useState(null);
	const [myAccountValues, setMyAccountValues] = useState({
		name: "",
		email: "",
		avatar: "",
	});

	useEffect(() => {
		if (myAccountData) {
			setMyAccountValues({
				...myAccountValues,
				name: myAccountData?.name,
				email: myAccountData?.email,
				// avatar: myAccountData?.avatar,
			});

			setImagePreview(myAccountData?.avatar || DummyImage);
		}
	}, [myAccountData]);

	//1- to hide input type file
	const fileInputRef = React.createRef();
	// 2- Trigger a click event on the hidden file input when the button is clicked
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	// to handle upload images files
	const handleUploadImages = (event) => {
		const file = event.target.files[0];

		setMyAccountValues({ ...myAccountValues, avatar: file });

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	// handle onchange values
	const handleMyAccountValues = (event) => {
		const { name, value } = event.target;

		setMyAccountValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	// handleSaveRegisterForm
	const handleSaveEditMyAccountValues = () => {
		const myAccountData =
			myAccountValues?.avatar !== ""
				? {
						name: myAccountValues?.name,
						email: myAccountValues?.email,
						avatar: myAccountValues?.avatar,
				  }
				: {
						name: myAccountValues?.name,
						email: myAccountValues?.email,
				  };

		dispatch(EditMyAccountThunk(myAccountData))
			.unwrap()
			.then((data) => {
				dispatch(GetMyAccountDataThunk());

				//  setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				// console.log(error);
				// handle error here
			});
	};
	return (
		<>
			<Helmet>
				<title>لوحة التحكم | حسابي</title>
			</Helmet>

			<div className='fixed top-[70px] left-0 z-50 w-full h-full min-h-ful overflow-y-auto pb-20 pt-5 hidden_body_scroll  bg-[#f6f6f6] '>
				<div className='w-[60%] m-auto py-4 '>
					{/* Pages Bread crumbs */}
					<section>
						<PagesBreadcrumbs
							currentPage={"حسابي"}
							parentPage={"الرئيسية"}
							isNested={true}
							route={"/"}
						/>
					</section>

					{/* the register form */}
					<section className='w-full  '>
						{reload ? (
							<div className='h-full w-full flex justify-center items-center '>
								<Loader />
							</div>
						) : (
							<>
								{/* image  */}
								<section className='mb-10  px-4'>
									<h6 className=' text-[#200407] text-[16px] font-normal mb-1 '>
										الصورة الشخصية
									</h6>

									<div className='w-52 '>
										<div className=' w-52  border-[1px] border-[#ddd] rounded-md p-1 mb-4 '>
											<div className='p-1 w-full h-full flex justify-center items-center'>
												{imagePreview ? (
													<img
														src={imagePreview}
														alt=''
														className='rounded-md1'
													/>
												) : (
													<img
														src={myAccountValues?.avatar}
														alt=''
														className='rounded-md  '
													/>
												)}
											</div>
										</div>

										<div className='w-full h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
											<input
												type='file'
												accept='image/*'
												onChange={handleUploadImages}
												ref={fileInputRef}
												/* hidden this input and use icon below to upload  */
												style={{ display: "none" }}
											/>

											{/* Button to trigger file input */}

											<button
												onClick={handleButtonClick}
												className='w-full h-full flex justify-center items-center'>
												<UploadImagesIcon
													className='w-6 h-6'
													title='تحديث صورة المستخدم'
												/>
											</button>
										</div>
									</div>
								</section>
								{/* User name  */}
								<section className='mb-6 px-4'>
									<h6 className=' text-[#200407] text-[16px] font-normal mb-1'>
										اسم المستخدم
									</h6>

									<div className='w-full h-11 bg-[#FFFFFF] rounded dashboard-routes-wights-style flex justify-start items-center px-2'>
										<UserIcon className='blog-icon' />

										<input
											type='text'
											name='name'
											onChange={handleMyAccountValues}
											value={myAccountValues?.name}
											placeholder='جمعية الأسرة المستدامة'
											className={`w-full  bg-transparent focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-3 px-2 `}
										/>
									</div>
								</section>

								{/* email  */}
								<section className='mb-10  px-4'>
									<h6 className=' text-[#200407] text-[16px] font-normal mb-1 '>
										البريد الالكتروني
									</h6>

									<div className='w-full h-11 bg-[#FFFFFF] rounded dashboard-routes-wights-style flex justify-start items-center px-2'>
										<EmailIcon className='blog-icon' />

										<input
											type='email'
											name='email'
											onChange={handleMyAccountValues}
											value={myAccountValues?.email}
											placeholder='sample.2023@gamil.com'
											className={` w-full h-full bg-transparent  focus:outline-none placeholder:text-[#C6C6C6]  text-[16px] font-normal font-Tajawal py-3 px-2 `}
										/>
									</div>
								</section>

								{/* Save Form Button  */}
								<section className='flex justify-start px-4'>
									<button
										disabled={
											myAccountValues?.name && myAccountValues?.email
												? false
												: true
										}
										className='w-[154px] h-14  flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-[#3AE374] rounded '
										onClick={handleSaveEditMyAccountValues}>
										<span>
											<SaveIcon />
										</span>
										<span className='text-white text-lg font-medium '>حفظ</span>
									</button>
								</section>
							</>
						)}
					</section>
				</div>
			</div>
		</>
	);
};

export default EditMyAccount;
