import React, { useState } from "react";

// icons
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
	CloseIcon
} from "../../assets/icons";
import AddNewsSectionModal from "../AddNewsSectionModal/AddNewsSectionModal";



const AddNewSection = ({
	setReload,
	reload,
	setShowAddSectionCom,
	showAddSectionCom,
}) => {
  const [sectionName, setSectionName] = useState("");
  let Navigate = useNavigate();

  // console.log("AddNewSection",reload)
  // from hooks

  const [showModalAddSection, setShowModalAddSection] = useState({
    show: false,
    type: "",
  });

  // handle Open add new question section
  const handleCloseAddNewQuestionSection = () => {
    setShowAddSectionCom(false);
  };






  return (
    <div className=" relative h-full w-full flex flex-col justify-between ">
      <section className="w-full">
        {/* add question name */}

        {/* add new question */}
        <section className="mb-8 flex flex-col items-stretch justify-start gap-[15px]">
          <h4 className=" text-[16px] font-[600] pb-[10px]">
            اختر محتوي القسم
          </h4>

          <button
            onClick={() => {
              setShowModalAddSection({ show: true, type: "slider" });
            }}
            // onClick={handleOpenAddNewQuestion}

            className="w-full h-11 border-[2px] border-dashed transition-[0.3s] hover:bg-white bg-yellow border-unnamed_color_119184 rounded cursor-pointer flex  justify-center items-center text-center text-lg text-unnamed_color_119184 font-medium "
          >
            <Add className=" font-[20px] text-unnamed_color_119184" />
            اضافة slider
          </button>
          <button
            onClick={() => {
              setShowModalAddSection({ show: true, type: "image_video" });
            }}
            // onClick={handleOpenAddNewQuestion}
            className="w-full h-11 border-[2px] border-dashed transition-[0.3s] hover:bg-white bg-yellow border-unnamed_color_119184 rounded cursor-pointer flex  justify-center items-center text-center text-lg text-unnamed_color_119184 font-medium "
          >
            <Add className=" font-[20px] text-unnamed_color_119184" />
            اضافة صورة/ فيديو
          </button>
          <button
            onClick={() => {
              setShowModalAddSection({ show: true, type: "image_video_title" });
            }}
            // onClick={handleOpenAddNewQuestion}
            className="w-full h-11 border-[2px] border-dashed transition-[0.3s] hover:bg-white bg-yellow border-unnamed_color_119184 rounded cursor-pointer flex  justify-center items-center text-center text-lg text-unnamed_color_119184 font-medium "
          >
            <Add className=" font-[20px] text-unnamed_color_119184" />
            اضافة صورة/ فيديو ونص
          </button>
          <button
            onClick={() => {
              setShowModalAddSection({ show: true, type: "files" });
            }}
            // onClick={handleOpenAddNewQuestion}
            className="w-full h-11 border-[2px] border-dashed transition-[0.3s] hover:bg-white bg-yellow border-unnamed_color_119184 rounded cursor-pointer flex  justify-center items-center text-center text-lg text-unnamed_color_119184 font-medium "
          >
            <Add className=" font-[20px] text-unnamed_color_119184" />
            ارفاق ملفات
          </button>
          <button
            onClick={() => {
              setShowModalAddSection({ show: true, type: "text" });
            }}
            // onClick={handleOpenAddNewQuestion}
            className="w-full h-11 border-[2px] border-dashed transition-[0.3s] hover:bg-white bg-yellow border-unnamed_color_119184 rounded cursor-pointer flex  justify-center items-center text-center text-lg text-unnamed_color_119184 font-medium "
          >
            <Add className=" font-[20px] text-unnamed_color_119184" />
            اضافة محتوى
          </button>
        </section>
      </section>
      {/* save and cancel button*/}
      <section className="w-full h-20 py-5   left-1/2   flex justify-center items-center gap-4">
        <button
          onClick={handleCloseAddNewQuestionSection}
          className="w-[154px] h-14 bg-unnamed_color_f6f6f6  flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed border-[1px] border-unnamed_color_055345 rounded "
        >
          <span>
            <CloseIcon className="close-icon-green" />
          </span>
          <span className="text-unnamed_color_055345 text-lg font-medium ">
            الغاء
          </span>
        </button>
      </section>
      {/* <AddNewQuestionModal reload={reload} setReload={setReload} /> */}
      {!reload && (
        <AddNewsSectionModal
          {...{
            reload,
            setReload,
            showModalAddSection,
            setShowModalAddSection,
          }}
        />
      )}
    </div>
  );
};

export default AddNewSection;
//  w-full  max-h-[550px] overflow-y-auto h-fit  pb-[40px]
