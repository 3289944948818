import React from "react";

// icon
import { BlogIcon, CloseIcon, SaveIcon } from "../../assets/icons";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { setCurrentSectionDataState } from "../../RTK/Reducers/SectionsReducer";
import { AddTextSectionThunk } from "../../RTK/Thunk/AddTextSectionThunk";
import { AllSectionsPagThunk } from "../../RTK/Thunk/AllSectionsPagThunk";
import { OneSectionThunk } from "../../RTK/Thunk/OneSectionThunk";
import TextEditorWithForm from "../textEditor/TextEditorWithForm";
import styles from "./AddNewText.module.css";
import { useState } from "react";

const schema = Yup.object().shape({
	title: Yup.string().required("قم بإضافة اسم القسم"),
	text: Yup.string().required("قم بإضافة المحتوى النصي"),
});
const AddNewText = ({ setShowModalAddSection, showModalAddSection }) => {
	let { currentSectionData } = useSelector((state) => state.SectionsReducer);
	// this context to open and close add new question
	let currentQuestions = false;
	let dispatch = useDispatch();
	let params = useParams();
	// from hooks
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			title: "",
			text: "",
		},
	});

	useEffect(
		(e) => {
			console.log(e);
			if (showModalAddSection?.isEdit && showModalAddSection?.targetId) {
				dispatch(
					OneSectionThunk({
						id: params?.sectionId,
						targetId: showModalAddSection?.targetId,
					})
				);
			}
		},
		[showModalAddSection]
	);

	// set err text default
	useEffect(() => {
		// errors?.page_icon;
		if (watch("text") !== "") {
			setError("text");
		}
	}, [setError, watch("text")]);
	useEffect(() => {
		if (currentSectionData !== null) {
			setValue("title", currentSectionData?.title);
			setValue("text", currentSectionData?.details?.text);
		}
	}, [currentSectionData]);

	useEffect(() => {
		return () => {
			dispatch(setCurrentSectionDataState());
		};
	}, []);

	const [showSaveBT, setShowSaveBT] = React.useState(true);

	const onSubmit = async (data) => {
		setShowSaveBT(false);

		dispatch(
			AddTextSectionThunk({
				...data,
				id: params?.sectionId,
				type: "text",
				targetId: showModalAddSection?.targetId
					? showModalAddSection?.targetId
					: null,
			})
		)
			.unwrap()
			.then((data) => {
				setShowSaveBT(true);

				dispatch(
					AllSectionsPagThunk({
						id: params?.sectionId,
						page: 1,
					})
				);
				setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				setShowSaveBT(true);

				// setError('registerInput', { type: 'custom', message: 'custom message' });
				if (error?.data?.title) {
					setError("title", { message: error?.data?.title });
					// setError('page_icon',);
				}
				if (error?.data?.text) {
					setError("text", { message: error?.data?.text });
				}

				// console.log(error);
				// handle error here
			});
		// console.log(data);
	};
	return (
		<>
			<div className='  px-[30px]  '>
				{/* Backdrop */}
				<div
					className={`${styles.backdrop} z-20`}
					onClick={() => setShowModalAddSection({ show: false, type: "" })}
				/>
				<div
					className={`z-[5000] absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-w-[750px]   w-[90%]  bg-white rounded-[10px]`}>
					<div
						className={`w-full h-11 ${
							showModalAddSection?.isEdit
								? "bg-[#b19f70]"
								: "bg-unnamed_color_055345"
						}   flex justify-center items-center px-6 ${styles.modal_title}`}>
						<h4
							className={`text-[16px] ${
								showModalAddSection?.isEdit
									? "text-unnamed_color_055345"
									: "text-white"
							}  font-medium flex-1 text-center`}>
							{showModalAddSection?.isEdit
								? ` تعديل المحتوى (${currentQuestions?.question})`
								: "  اضافة محتوى"}
						</h4>
						<div
							className='cursor-pointer '
							onClick={() => setShowModalAddSection({ show: false, type: "" })}>
							<CloseIcon
								className={`${
									showModalAddSection?.isEdit
										? "close-icon-black"
										: "close-icon-white"
								}`}
							/>
						</div>
					</div>
					{/* title of modal */}
					<form
						onSubmit={handleSubmit(onSubmit)}
						className=' w-full  max-h-[600px] overflow-y-auto h-fit  pb-[40px]'>
						<>
							<div className='p-[25px]'>
								<>
									<div className='w-full border-[1px] rounded-[2px] border-unnamed_color_C6C6C6 h-14 flex justify-start items-center gap-3 p-3 bg-unnamed_color_f6f6f6 add-new-section-title-shadow mb-[12px]'>
										<BlogIcon className='blog-icon' />
										<input
											className='w-full h-full bg-transparent placeholder:text-[#C6C6C6] text-lg font-normal text-black'
											type='text'
											placeholder='اسم القسم'
											{...register("title")}
										/>
									</div>
									<div className=' w-full  flex justify-start mb-[20px] items-start gap-[6px] text-ured'>
										<span>{errors?.title?.message}</span>
									</div>
								</>
								<TextEditorWithForm
									placeholder={" المحتوى النصي للقسم المحتوى"}
									height={"300px"}
									setValue={setValue}
									watch={watch}
									register={register}
									name='text'
								/>
								<div className=' w-full  flex justify-start mb-[20px] items-start gap-[6px] text-ured'>
									<span>{errors?.text?.message}</span>
								</div>
							</div>
							{/* content of modal */}
							{showSaveBT ? (
								<button
									type='submit'
									className='w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							) : (
								<button
									type='button'
									className='w-[154px] !cursor-wait h-14 m-auto flex justify-center items-center gap-2 p-3  disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							)}
						</>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddNewText;
