import React from "react";
import { Helmet } from "react-helmet";

// import components
import { PagesBreadcrumbs } from "../../../components";

// import icons and images

// import css styles
import { Switch } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	changePageDataAction,
	changePageStatusAction,
} from "../../../RTK/Reducers/HomePageReducer";
import { AllMainPagesSectionThunk } from "../../../RTK/Thunk/AllMainPagesSectionThunk";
import { ChangeStatusMainPageThunk } from "../../../RTK/Thunk/ChangeStatusMainPageThunk";
import "../../../index.css";
const SwitchStyle = {
	width: "25px",
	height: "14px",
	padding: "0",
	"& .MuiButtonBase-root ": {
		color: "#fff",
		"&.Mui-checked": {
			color: "#fff",
		},

		"&:hover": {
			backgroundColor: "transparent",
		},

		"&.Mui-checked+.MuiSwitch-track": {
			backgroundColor: "#3AE374",
			opacity: "1",
		},
	},
	"& .MuiSwitch-switchBase ": {
		padding: "1px",
		"&.Mui-checked": {
			transform: "translateX(11px)",
		},
	},

	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
		boxShadow: "none",
	},
};

let homeBox = [
	{
		title: "القسم الرئيسي",
		link: "main-section",
		types: {},
		name: `hero`,
	},

	{
		title: `من نحن`,
		link: "who-are-we",
		types: {},
		name: `من نحن`,
	},
	{
		title: "الخدمات الإلكترونية",
		link: "electronic-services",
		types: {},
		name: "الخدمات الإلكترونية",
	},
	{
		title: `إنجازاتنا`,
		link: "achievements",
		types: {},
		name: `إنجازاتنا`,
	},
	{
		title: `الأخبار`,
		link: "news",
		types: {},
		name: `الأخبار`,
	},
	{
		title: `البوم الصور
`,
		link: "images",
		types: {},
		name: `البوم الصور`,
	},
	{
		title: `البوم الفيديو
`,
		link: "video-albums",
		types: {},
		name: `البوم الفيديو`,
	},
	{
		title: `قالوا عنا
`,
		link: "testimonials",
		types: {},
		name: `قالوا عنا`,
	},
	{
		title: `شركاؤنا`,
		link: "partners",
		types: {},
		name: `شركاؤنا`,
	},
	{
		title: "type",
		link: "",
		types: {},
		name: `type`,
	},
];
const WebsiteHomePage = () => {
	const navigate = useNavigate();
	let dispatch = useDispatch();
	let { mainPageStatus } = useSelector((state) => state.HomePageReducer);
	const getMainPageStatusRef = useRef(true);
	useEffect(() => {
		if (getMainPageStatusRef.current) {
			getMainPageStatusRef.current = false;
			dispatch(AllMainPagesSectionThunk());
		}
	}, [getMainPageStatusRef]);
	const dashboardPagesRouts = homeBox.map((box, index) => (
		<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
			<div
				key={index}
				className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
				<div
					className=' h-full flex flex-col justify-center items-center gap-3'
					onClick={() => {
						dispatch(changePageDataAction({ name: box?.name }));
						navigate(`/website-home-page-change/${box?.link}/${box?.title}`);
					}}>
					{/* <div className=''>{box?.icon}</div> */}
					<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
						{box?.title}
					</div>
				</div>
			</div>
			<Switch
				title='تعطيل/تفعيل'
				sx={SwitchStyle}
				// onChange={() => changeItemsStatus("type")}
				checked={mainPageStatus?.electronic_services == 1 ? true : false}
			/>
		</div>
	));
	// console.log(mainPageStatus?.main_sections);

	let changeItemsStatus = (type) => {
		// console.log(type)
		dispatch(
			ChangeStatusMainPageThunk({
				type,
			})
		)
			.unwrap()
			.then((data) => {
				// console.log(data)
				dispatch(
					changePageStatusAction({
						type,
					})
				);
			});
	};
	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادات الصفحة الرئيسية</title>
			</Helmet>
			<div className='py-4 px-14'>
				{/*  Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						currentPage={"الرئيسية"}
						parentPage={null}
						isNested={false}
					/>
				</section>
				<div className='m-auto dashboard-routes-wights-width flex flex-wrap justify-start items-center gap-[30px] py-10'>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									navigate(
										`/website-home-page-change/main-section/القسم الرئيسي`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									القسم الرئيسي
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("main_sections")}
							checked={mainPageStatus?.main_sections == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[1]?.link}/${homeBox[1]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[1]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("who_are_we")}
							//               checked={mainPageStatus?.electronic_services == 1 ? true : false}

							checked={mainPageStatus?.who_are_we == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									navigate(
										`/website-home-page-change/${homeBox[2]?.link}/${homeBox[2]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[2]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("electronic_services")}
							checked={mainPageStatus?.electronic_services == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[3]?.link}/${homeBox[3]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[3]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("results")}
							checked={mainPageStatus?.results == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[4]?.link}/${homeBox[4]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[4]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("news")}
							checked={mainPageStatus?.news == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[5]?.link}/${homeBox[5]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[5]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("images")}
							checked={mainPageStatus?.images == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[6]?.link}/${homeBox[6]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[6]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("videos")}
							checked={mainPageStatus?.videos == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[7]?.link}/${homeBox[7]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[7]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("testimonials")}
							checked={mainPageStatus?.testimonials == 1 ? true : false}
						/>
					</div>
					<div className=' flex justify-center flex-col gap-[15px] items-center w-fit h-fit'>
						<div className=' w-40 h-[141px] transition hover:bg-unnamed_color_119184 bg-unnamed_color_055345 dashboard-routes-wights-style cursor-pointer'>
							<div
								className=' h-full flex flex-col justify-center items-center gap-3'
								onClick={() => {
									// dispatch(changePageDataAction({ name: box?.name }));
									navigate(
										`/website-home-page-change/${homeBox[8]?.link}/${homeBox[8]?.name}`
									);
								}}>
								{/* <div className=''>{box?.icon}</div> */}
								<div className='text-center px-[20px] text-xl text-white font-Tajawal font-normal'>
									{homeBox[8]?.title}
								</div>
							</div>
						</div>
						<Switch
							title='تعطيل/تفعيل'
							sx={SwitchStyle}
							onChange={() => changeItemsStatus("partners")}
							checked={mainPageStatus?.partners == 1 ? true : false}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default WebsiteHomePage;
