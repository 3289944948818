import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationBox = ({ count, setPageTarget, current_page }) => {
	const handleChange = (event, value) => {
		setPageTarget(value);
	};

	return (
		<div
			className='pagination-box flex justify-center items-center mt-[40px]'
			dir='ltr'>
			<Stack spacing={2}>
				<Pagination
					count={count}
					page={current_page || 1}
					size='large'
					onChange={handleChange}
				/>
			</Stack>
		</div>
	);
};

export default PaginationBox;
