import React, { useContext } from "react";
import Context from "../../context/Context";

// import pages
import PrivateRoute from "../login/PrivateRoute/PrivateRoute";

// import components
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { SideBar, TopBar } from "../../components";

// MODAL COMPONENTS
import LoadingModal from "../../components/loadingModal/LoadingModal";
import SaveOrDeleteModal from "../../components/saveOrDeleteModal/SaveOrDeleteModal";

// import css styles
import { ToastContainer } from "react-toastify";
import ConfirmDeleteActionModal from "../../components/saveOrDeleteModal/confirmDeleteAction/ConfirmDeleteAction";
import "../../index.css";

const DashboardContent = () => {


	const location = useLocation();
	// to handle show and hidden actions (save or delete modal) alert and loading
	const actionModalContext = useContext(Context);
	const { actionTitle, loadingValue, theItemToBeDeleted } = actionModalContext;
// ====================================

	return (
		<PrivateRoute>
			{/*  top bar of dashboard */}
			<div className='pb-[70px]'>
				<TopBar />
			</div>

			{/* ACTION ALERT MESSAGES MODAL AND MODAL  */}
			{theItemToBeDeleted && <ConfirmDeleteActionModal />}
			{actionTitle && <SaveOrDeleteModal />}
			{loadingValue && <LoadingModal />}

			{/* This is Toast Library to Handle errors modal in dashboard*/}
			<ToastContainer
				rtl
				draggable
				closeOnClick
				autoClose={2000}
				pauseOnFocusLoss
				position='top-center'
				newestOnTop={false}
				hideProgressBar={false}
				style={{ fontSize: "14px", color: "#000", whiteSpace: "normal" }}
			/>

			{/* DASHBOARD BODY  */}
			{location.pathname !== "/" ? (
				<div
					className={`flex items-start ${
						location.pathname === "/program-advantages" ||
						location.pathname === "/common-questions" ||
						location.pathname === "/create-page"
							? "bg-white"
							: "bg-[#f6f6f6]"
					}`}>
					{/*  side bar of dashboard */}
					<div className='w-max flex justify-center items-start fixed '>
						<SideBar />
					</div>

					{/* the content of dashboard */}
					<div className='dashboard-content '>
						{/** use ScrollRestoration from react router dom to fix scrolling issue */}
						<ScrollRestoration />
						<Outlet />
					</div>
				</div>
			) : (
				<div className='main-content '>
					<Outlet />
				</div>
			)}
		</PrivateRoute>
	);
};

export default DashboardContent;
