import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, TableBody } from "@mui/material";
import * as Yup from "yup";
import {
	BlogIcon,
	ImagesIcon,
	TextIcon,
	UploadImagesIcon,
} from "../../../assets/icons";
import { PagesBreadcrumbs, SectionLabel } from "../../../components";
import PaginationBox from "../../../components/PaginationBox/PaginationBox";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

// css styles

import { DeleteForever, Edit, ModeEdit } from "@mui/icons-material";
import { PlusIcon } from "evergreen-ui";
import {
	setCurrentSectionDataStateRow,
	setSectionDataState,
} from "../../../RTK/Reducers/HomePageReducer";
import { AddMainSectionInHomeThunk } from "../../../RTK/Thunk/AddMainSectionInHomeThunk";
import { AllMainSectionDataThunk } from "../../../RTK/Thunk/AllMainSectionDataThunk";
import { DeleteCurrentRowInMainSectionThunk } from "../../../RTK/Thunk/DeleteCurrentRowInMainSectionThunk";
import { OneMainPagesSectionThunk } from "../../../RTK/Thunk/OneMainPagesSectionThunk";
import AlertDialog from "../../../components/AlertBox/AlertBox";
import EmptyBox from "../../../components/EmptyBox/EmtyBox";
import { Loader } from "../../../components/loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#b19f70",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const schema = Yup.object().shape({
	ingredients: Yup.array()
		// test select options and radio buttons
		.of(
			Yup.object().shape({
				link: Yup.string().optional("قم بإضافة ربط الملف"),
				image: Yup.mixed().required("قم بإضافة الملف"),
			})
		),
});
const HomeMainSection = ({ showModalAddSection }) => {
	let { name } = useParams();
	let dispatch = useDispatch();
	let {
		section_type,
		SectionData,
		lastPage,
		reload,
		currentPage,
		currentSectionData,
	} = useSelector((state) => state.HomePageReducer);
	// =======react-form====================
	const {
		control,
		handleSubmit,
		register,
		setValue,
		watch,
		setError,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			ingredients: [
				{
					link: "",
					image: null,
					previewImgOrVideo: null,
				},
			],
		},
	});

	// console.log("ingredients", watch("ingredients"));
	const {
		fields: ingredientsFields,
		append: appendIngredientField,
		remove: removeIngredientField,
	} = useFieldArray({
		control,
		name: "ingredients",
	});

	const fileInputRef = React.createRef();

	// Function to handle file input change
	const handleSetImageInput = (blockIndex, event) => {
		const file = event?.target?.files[0];
		const imgConvert = URL.createObjectURL(file);

		const updatedBlocks = [...watch("ingredients")];
		updatedBlocks[blockIndex].image = file;

		updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

		setValue("ingredients", updatedBlocks);
	};
	// Function to open the file input dialog

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const programAdvantageBlock = () => {
		return (
			ingredientsFields?.length > 0 && (
				<>
					{ingredientsFields.map((block, blockIndex) => {
						return (
							<>
								{/* Create program items  */}
								<section key={blockIndex} className=' py-[40px] px-[20px]'>
									{/* Create item input */}
									<>
										{/* Main Title of page */}
										<div className=' bg-unnamed_color_f6f6f6 p-[20px] mb-[20px]'>
											<div className='mb-[20px]'>
												<SectionLabel label={"صورة "} icon={<ImagesIcon />} />
											</div>
											<div className='w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
												<input
													type='file'
													accept='image/*'
													control={control}
													onChange={(selectedImageOrVideo) => {
														if (selectedImageOrVideo?.target?.files[0]) {
															setError(`ingredients.${blockIndex}.image`);
															handleSetImageInput(
																blockIndex,
																selectedImageOrVideo
															);
														}
													}}
													ref={fileInputRef}
													name='image'
													/* hidden this input and use icon below to upload  */
													style={{ display: "none" }}
												/>

												{/* Button to trigger file input */}
												<button
													// type="button"
													onClick={handleButtonClick}
													className='w-full h-full flex justify-center items-center'>
													{showModalAddSection?.showModalAddSection?.isEdit &&
													typeof watch(`ingredients.${0}.image`) == "string" &&
													showModalAddSection?.targetId ? (
														<div className='p-1 w-full h-full flex justify-center items-center'>
															<img
																src={watch(
																	`ingredients.${blockIndex}.previewImgOrVideo`
																)}
																alt={watch(
																	`ingredients.${blockIndex}.previewImgOrVideo`
																)}
																className='h-full '
															/>
														</div>
													) : block?.image ? (
														block?.previewImgOrVideo !== null &&
														(block?.previewImgOrVideo?.endsWith(".png") ||
															block?.image?.type?.startsWith("image/")) ? (
															<div className='p-1 w-full h-full flex justify-center items-center'>
																<img
																	src={block?.previewImgOrVideo}
																	alt={block?.previewImgOrVideo}
																	className='h-full '
																/>
															</div>
														) : (
															<>
																{/* <div className="p-1 w-full h-full flex justify-center items-center">
                              {block?.previewImgOrVideo}
                            </div> */}
															</>
														)
													) : (
														<UploadImagesIcon
															className='w-6 h-6'
															title='تغيير صورة '
														/>
													)}
												</button>
											</div>
											{errors?.ingredients &&
												errors?.ingredients[blockIndex]?.image && (
													<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
														<span>
															{errors?.ingredients[blockIndex]?.image?.message}
														</span>
													</div>
												)}
										</div>

										{/* Embed YouTube Video  */}
										<div className='w-full flex-col flex justify-start items-start  mb-[30px]'>
											<SectionLabel
												label={" الرابط الخاص "}
												icon={<BlogIcon className='programBlogIcon' />}
											/>
											<div className='w-full  h-12 border-[1px] border-[#E8E8E8] bg-[##9ED1B8]  rounded flex justify-start items-center'>
												{/* Add info value to packages */}
												<input
													type='text'
													value={block?.point}
													placeholder={`الرابط الخاص برقم ${blockIndex + 1}`}
													{...register(`ingredients.${blockIndex}.link`)}
													className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
												/>
											</div>
											{errors?.ingredients &&
												errors?.ingredients[blockIndex]?.link && (
													<div className=' w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured'>
														<span>
															{errors?.ingredients[blockIndex]?.link?.message}
														</span>
													</div>
												)}
										</div>
									</>
									{/* Add more item button */}
								</section>
							</>
						);
					})}
				</>
			)
		);
	};
	// ==================================
	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [pageTarget, setPageTarget] = useState(1);
	const [targetEdit, setTargetEdit] = useState({ type: false, id: "" });

	// ====get table data====
	useEffect(() => {
		dispatch(
			AllMainSectionDataThunk({ page: pageTarget, type: "main_sections" })
		);
	}, [pageTarget]);

	// ====handle delete====
	const handleDeleteRow = (id) => {
		dispatch(
			DeleteCurrentRowInMainSectionThunk({
				id: id,
				type: "main_sections",
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(
					AllMainSectionDataThunk({ page: pageTarget, type: "main_sections" })
				);
			})
			.catch((error) => {});
	};
	// get current data target updated
	useEffect(() => {
		if (targetEdit?.type && targetEdit?.id) {
			dispatch(
				OneMainPagesSectionThunk({
					id: targetEdit?.id,
					type: "main_sections",
				})
			);
		}
	}, [targetEdit]);
	const currentSectionDataRef = React.useRef(true);
	useEffect(() => {
		if (currentSectionData !== null && currentSectionDataRef.current) {
			// console.log(currentSectionData)
			// set ingredients arr data
			removeIngredientField(0);
			appendIngredientField({
				link: currentSectionData?.link || "",
				image: currentSectionData?.image,
				previewImgOrVideo: currentSectionData?.image,
			});

			currentSectionDataRef.current = false;
		}
	}, [currentSectionData]);
	// ==================
	let setFunctionWithEdit = () => {
		setValue("text", "");
		setTargetEdit({ type: false, id: "" });
		window.scroll(0, 0);
		appendIngredientField({
			title: "",
			image: null,
			previewImgOrVideo: null,
			link: "",
		});
		removeIngredientField(0);
		dispatch(setCurrentSectionDataStateRow());
	};
	const onSubmit = async (data) => {
		// console.log(data)
		dispatch(
			AddMainSectionInHomeThunk({
				...data,
				//  id: params?.sectionId,
				type: "main_sections",
				id: targetEdit?.id ? targetEdit?.id : null,
			})
		)
			.unwrap()
			.then((data) => {
				setFunctionWithEdit();
				dispatch(
					AllMainSectionDataThunk({ page: pageTarget, type: "main_sections" })
				);
				//  setShowModalAddSection({ show: false, type: "" });
			})
			.catch((error) => {
				if (error?.data) {
					// if (error?.data["slider.title"]) {
					//   // console.log(error?.data["slider.link"]);
					//   setError(`ingredients.${0}.title`, {
					//     message: error?.data["slider.title"],
					//   });
					// }
					watch(`ingredients`)?.forEach((el, index) => {
						if (error?.data?.link) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.link`, {
								message: error?.data?.link,
							});
						}
						if (error?.data?.image) {
							// console.log(error?.data["slider.link"]);
							setError(`ingredients.${index}.image`, {
								message: error?.data?.image,
							});
						}
						if (error?.data?.title) {
							setError(`ingredients.${index}.title`, {
								message: error?.data?.title,
							});
						}
					});
				}

				// console.log(error);
				// handle error here
			});
	};
	useEffect(() => {
		return () => {
			dispatch(setSectionDataState());
			dispatch(setCurrentSectionDataStateRow());
		};
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | إعدادات الصفحة الرئيسية</title>
			</Helmet>
			<div className='py-4 px-14'>
				{/*  Pages Bread crumbs */}
				<section>
					<PagesBreadcrumbs
						parentPage={"الرئيسية"}
						currentPage={`${name}`}
						isNested={true}
						route={"/website-home-page"}
					/>
				</section>
				{reload ? (
					<Loader />
				) : (
					<form className='p-[25px]' onSubmit={handleSubmit(onSubmit)}>
						{section_type?.main_title == true && (
							<>
								<SectionLabel label={"عنوان"} icon={<TextIcon />} />
								<div className=' min-h-[56px] h-auto bg-[##9ED1B8] rounded-2xl section-box-shadow flex justify-start items-center '>
									<input
										type='text'
										className='w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]'
									/>
								</div>
							</>
						)}

						{programAdvantageBlock()}
						<div>
							{!targetEdit?.type && (
								<button
									title='اضافه سلايد جديد'
									type='submit'
									onClick={() => {
										window.scroll(0, 0);
									}}
									className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-unnamed_color_119184 border-unnamed_color_119184   bg-[#F1F8F5]   rounded  cursor-pointer'>
									<>
										اضافه
										<PlusIcon className='add-icon' />
									</>
								</button>
							)}
							{targetEdit?.type && (
								<div className=' flex justify-center items-center w-full gap-[20px]'>
									<button
										title='تعديل سلايد'
										type='submit'
										onClick={() => {
											window.scroll(0, 0);
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-unnamed_color_119184 border-unnamed_color_119184   bg-[#F1F8F5]   rounded  cursor-pointer'>
										<>
											تعديل
											<Edit className='add-icon' />
										</>
									</button>

									<div
										title='الغاء التعديل'
										onClick={() => {
											setFunctionWithEdit();
										}}
										className='w-[100%] mt-[40px]  h-12 border-[2px] flex justify-center items-center gap-[5px]  border-dashed text-[17px] font-[500] text-unnamed_color_119184 border-unnamed_color_119184   bg-[#F1F8F5]   rounded  cursor-pointer'>
										<>
											الغاء التعديل
											<Edit className='add-icon' />
										</>
									</div>
								</div>
							)}
						</div>
					</form>
				)}

				<div className=' w-full h-[2px] bg-unnamed_color_C6C6C6 mb-[40px] mt-[60px]'></div>

				<>
					{reload ? (
						<Loader />
					) : SectionData.length ? (
						<>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 700 }} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الرقم
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الصورة
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الرابط
											</StyledTableCell>

											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												أجراءات
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{SectionData?.map((row, index) => (
											<StyledTableRow key={row.id}>
												<StyledTableCell align='center'>
													{index + 1}
												</StyledTableCell>

												<StyledTableCell align='' className='!text-center'>
													<img
														src={row.image}
														alt=''
														className=' object-cover w-[60px] h-[60px] mx-auto'
													/>
												</StyledTableCell>
												<StyledTableCell
													align='center'
													style={{ direction: "ltr" }}>
													<div className='truncate w-[300px] mx-auto'>
														{row.link}
													</div>
												</StyledTableCell>
												<StyledTableCell align='center'>
													<div className='action flex items-center justify-center gap-2'>
														<IconButton
															aria-label=''
															onClick={() => {
																currentSectionDataRef.current = true;
																window.scroll(0, 0);
																setTargetEdit({ type: true, id: row.id });
															}}>
															<ModeEdit />
														</IconButton>

														<IconButton
															aria-label=''
															onClick={() => {
																setOpenAlert(true);
																setDeleteId(row.id);
															}}>
															<DeleteForever className='text-[red]' />
														</IconButton>
													</div>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<PaginationBox
								current_page={currentPage}
								count={lastPage}
								setPageTarget={setPageTarget}
							/>
						</>
					) : (
						<EmptyBox />
					)}
					<AlertDialog
						open={openAlert}
						setOpen={setOpenAlert}
						handleDelete={handleDeleteRow}
						deleteId={deleteId}
						setDeleteId={setDeleteId}
					/>
				</>
			</div>
		</>
	);
};

export default HomeMainSection;
